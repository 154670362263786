<template>
    <el-tabs v-model="rolePermissionsTab" type="card" class="education-content tabs-full" @tab-click="toggleRoleTab">
        <el-tab-pane label="菜单分组" name="menuGroup">
            <MenuGroup ref="menuGroup"></MenuGroup>
        </el-tab-pane>
        <el-tab-pane label="菜单路由管理" name="menuRouteManage">
            <MenuRouteManage ref="menuRouteManage"></MenuRouteManage>
        </el-tab-pane>
        <el-tab-pane label="角色管理" name="roleManage">
            <RoleManage ref="roleManage"></RoleManage>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import MenuGroup from '@/components/admin/roleManage/MenuGroup.vue'
    import MenuRouteManage from '@/components/admin/roleManage/MenuRouteManage.vue'
    import RoleManage from '@/components/admin/roleManage/RoleManage.vue'

    export default {
        name: "RolePermissions",
        data() {
            return {
                rolePermissionsTab: 'menuGroup'
            }
        },
        components: {
            MenuGroup,
            MenuRouteManage,
            RoleManage
        },
        methods: {
            toggleRoleTab() {
                this.$refs.menuGroup.getMenuGroupList();
                this.$refs.menuRouteManage.getMenuRouteList();
                this.$refs.menuRouteManage.getMenuGroupList();
                this.$refs.roleManage.getRouteList();
                this.$refs.roleManage.getAllRouteList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
    }
</style>