<template>
    <div class="role-wrapper">
        <div class="role-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/rolepermissions' }">角色权限管理</el-breadcrumb-item>
                <el-breadcrumb-item>菜单路由管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addRoute">创建路由</el-button>
        </div>
        <el-table :data="menuRouteList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="id" align="center" label="ID"></el-table-column>
            <el-table-column prop="name" align="center" label="菜单名称"></el-table-column>
            <el-table-column prop="route" align="center" label="路由"></el-table-column>
            <el-table-column prop="group_name" align="center" label="分组"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" type="primary" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="menuRoutePages.currentPageNum"
                       :page-size="menuRoutePages.eachPageNum"
                       :total="menuRoutePages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="menuRouteCurrentChange">
        </el-pagination>
        <!--创建/编辑路由 弹窗-->
        <el-dialog :title="routeOperTitle" :visible.sync="addRouteDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addRouteForm" ref="addRouteForm" :rules="routeRules" label-position="right" label-width="100px">
                <el-form-item label="菜单分组" prop="p_id">
                    <el-select v-model="addRouteForm.p_id" placeholder="请选择" style="width: 360px;">
                        <el-option
                                v-for="item in menuGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="菜单名称" prop="menu_name">
                    <el-input v-model="addRouteForm.menu_name" autocomplete="off" placeholder="1-30字"></el-input>
                </el-form-item>
                <el-form-item label="模块" prop="module">
                    <el-input v-model="addRouteForm.module" autocomplete="off" placeholder="1-30字"></el-input>
                </el-form-item>
                <el-form-item label="控制器" prop="controller">
                    <el-input v-model="addRouteForm.controller" autocomplete="off" placeholder="1-30字"></el-input>
                </el-form-item>
                <el-form-item label="方法" prop="method">
                    <el-input v-model="addRouteForm.method" autocomplete="off" placeholder="1-30字"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="saveForm('addRouteForm')">确 定</el-button>
                <el-button size="medium" @click="addRouteDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "MenuRouteManage",
        data() {
            return {
                //菜单路由列表
                menuRouteList: [],
                //分页
                menuRoutePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                //创建/编辑菜单路由弹窗标题
                routeOperTitle: '创建路由',
                addRouteDialog: false,
                //菜单分组列表
                menuGroupList: [],
                //菜单路由数据
                addRouteForm: {
                    id: '',
                    p_id: '',
                    menu_name: '',
                    module: '',
                    controller: '',
                    method: ''
                },
                routeRules: {
                    p_id: [
                        { required: true, message: '请选择菜单分组', trigger: 'change' }
                    ],
                    menu_name: [
                        { required: true, message: '请输入菜单名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ],
                    module: [
                        { required: true, message: '请输入模块名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ],
                    controller: [
                        { required: true, message: '请输入控制器名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ],
                    method: [
                        { required: true, message: '请输入方法名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ]
                },
                distinguishBtn: '',
            }
        },
        mounted() {
            this.getMenuRouteList();
            this.getMenuGroupList();
        },
        methods: {
            //获取菜单分组列表
            getMenuGroupList() {
                this.$http.axiosGet(this.$api.MGList, (res) => {
                    if (res.code === 200) {
                        this.menuGroupList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取菜单路由列表
            getMenuRouteList() {
                let param = {
                    type: 'page',
                    page: this.menuRoutePages.currentPageNum,
                    limit: this.menuRoutePages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.MRList, param, (res) => {
                    if (res.code === 200) {
                        this.menuRouteList = res.data.data;
                        this.menuRoutePages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //添加
            addRoute() {
                this.addRouteDialog = true;
                this.distinguishBtn = 'add';
            },
            //编辑
            editBtn(row) {
                this.addRouteDialog = true;
                this.distinguishBtn = 'edit';
                this.routeOperTitle = '编辑路由';
                this.$http.axiosGetBy(this.$api.getMR, {menu_route_id: row.id}, (res) => {
                    if (res.code === 200) {
                        this.addRouteForm.p_id = res.data.p_id;
                        this.addRouteForm.menu_name = res.data.name;
                        this.addRouteForm.module = res.data.module;
                        this.addRouteForm.controller = res.data.controller;
                        this.addRouteForm.method = res.data.action;
                        this.addRouteForm.id = row.id;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //删除
            deleteBtn(id) {
                console.log(id)
                this.$confirm('是否删除该路由，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delMR, {menu_route_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getMenuRouteList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //分页
            menuRouteCurrentChange(val) {
                this.menuRoutePages.currentPageNum = val;
                this.getMenuRouteList();
            },
            //保存菜单分组
            saveForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('p_id', this.addRouteForm.p_id);
                            formData.append('menu_route_name', this.addRouteForm.menu_name);
                            formData.append('module', this.addRouteForm.module);
                            formData.append('controller', this.addRouteForm.controller);
                            formData.append('action', this.addRouteForm.method);
                            this.$http.axiosPost(this.$api.saveMR, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getMenuRouteList();
                                    this.addRouteDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('menu_route_id', this.addRouteForm.id);
                            formData.append('p_id', this.addRouteForm.p_id);
                            formData.append('menu_route_name', this.addRouteForm.menu_name);
                            formData.append('module', this.addRouteForm.module);
                            formData.append('controller', this.addRouteForm.controller);
                            formData.append('action', this.addRouteForm.method);
                            this.$http.axiosPost(this.$api.saveMR, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getMenuRouteList();
                                    this.addRouteDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //关闭菜单分组弹窗
            resetForm() {
                this.addRouteForm = {
                    id: '',
                    p_id: '',
                    menu_name: '',
                    module: '',
                    controller: '',
                    method: ''
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .role-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .role-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .el-link + .el-link {
            margin-left: 10px;
        }
    }
</style>