<template>
    <div class="role-wrapper">
        <div class="role-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/rolepermissions' }">角色权限管理</el-breadcrumb-item>
                <el-breadcrumb-item>菜单分组</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addGroup">创建菜单</el-button>
        </div>
        <el-table :data="menuGroupList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="分组名称"></el-table-column>
            <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" type="primary" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="menuGroupPages.currentPageNum"
                       :page-size="menuGroupPages.eachPageNum"
                       :total="menuGroupPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="menuGroupCurrentChange">
        </el-pagination>
        <!--创建/编辑菜单分组 弹窗-->
        <el-dialog :title="menuOperTitle" :visible.sync="addGroupDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" @opened="openAddGroup" :close-on-click-modal="false">
            <el-form :model="addGroupForm" ref="addGroupForm" :rules="groupRules" label-position="right" label-width="100px">
                <el-form-item label="分组名称" prop="group_name">
                    <el-input v-model="addGroupForm.group_name" ref="groupInput" autocomplete="off" placeholder="1-30字"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="saveForm('addGroupForm')">确 定</el-button>
                <el-button size="medium" @click="addGroupDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "MenuGroup",
        data() {
            return {
                //菜单分组列表
                menuGroupList: [],
                //分页
                menuGroupPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                //创建/编辑菜单分组弹窗标题
                menuOperTitle: '创建菜单分组',
                addGroupDialog: false,
                //菜单分组数据
                addGroupForm: {
                    id: '',
                    group_name: ''
                },
                groupRules: {
                    group_name: [
                        { required: true, message: '请输入分组名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ]
                },
                distinguishBtn: '',
            }
        },
        mounted() {
          this.getMenuGroupList();
        },
        methods: {
            //获取菜单分组列表
            getMenuGroupList() {
                let param = {
                    type: 'page',
                    page: this.menuGroupPages.currentPageNum,
                    limit: this.menuGroupPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.MGList, param, (res) => {
                    if (res.code === 200) {
                        this.menuGroupList = res.data.data;
                        this.menuGroupPages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //添加
            addGroup() {
                this.addGroupDialog = true;
                this.distinguishBtn = 'add';
            },
            //编辑
            editBtn(row) {
                this.addGroupForm.group_name = row.name;
                this.addGroupForm.id = row.id;
                this.addGroupDialog = true;
                this.distinguishBtn = 'edit';
                this.menuOperTitle = '编辑菜单分组';
            },
            // 删除
            deleteBtn(id) {
                this.$confirm('是否删除该分组，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delMG, {menu_group_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getMenuGroupList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //分页
            menuGroupCurrentChange(val) {
                this.menuGroupPages.currentPageNum = val;
                this.getMenuGroupList();
            },
            //打开弹窗光标定位
            openAddGroup() {
                this.$refs.groupInput.focus();
            },
            //保存菜单分组
            saveForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('menu_group_name', this.addGroupForm.group_name);
                            this.$http.axiosPost(this.$api.saveMG, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getMenuGroupList();
                                    this.addGroupDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('menu_group_name', this.addGroupForm.group_name);
                            formData.append('menu_group_id', this.addGroupForm.id);
                            this.$http.axiosPost(this.$api.saveMG, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getMenuGroupList();
                                    this.addGroupDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //关闭菜单分组弹窗
            resetForm() {
                this.addGroupForm = {
                    id: '',
                    group_name: ''
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .role-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .role-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .el-link + .el-link {
            margin-left: 10px;
        }
    }
</style>